import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Checkbox, Form, Input, Placeholder, Select } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { JOBLISTSTATE_ACTIONS } from '../actions/jobListState'
import { Empty, Job } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
const moment = require('moment-timezone')

const { app, jobs, jobEdit, newEl, jobwizard, fileimport } = routes

class Jobs extends Component {
    constructor(props) {
        super(props)
        let type = this.props.type ? this.props.type : 'full'
        let direction = this.props.direction ? this.props.direction : 'column'
        let select = !!this.props.select
        let searchable = !!this.props.searchable
        let sub = !!this.props.sub

        let updateSearch = debounce(200, false, (page, items, selectSearch, isEmployee) => {
            this.state.network.getDetailedJobList(
                page,
                items,
                selectSearch,
                isEmployee,
                this.props.createdExternal,
                0,
                'all',
                this.props.jobType
                    ? {
                          ...this.state.filters,
                          jobType: {
                              value: this.props.jobType ? this.props.jobType : 2,
                              key: 'jobType',
                              operator: '=',
                          },
                      }
                    : this.state.filters,
                this.state.sorts
            )
        })

        this.state = {
            page: 0,
            items: 10,
            type,
            direction,
            select,
            searchable,
            selectedJob: {},
            jobOptions: [],
            selectSearch: '',
            selectOpen: false,
            firstTime: true,
            opened: {},
            scrollTop: 0,
            sub,
            onLoaded: null,
            selected: this.props.selected ? this.props.selected : {},
            updateSearch,
            filters: {},
            sorts: {},
        }

        this.updateFiltersDebounced = debounce(500, false, (filters) => {
            // console.log('Update filters debounced')
            let {
                search: { data: searchText },
            } = this.state

            this.setState({ filters, page: 0 }, () => {
                this.state.network.getDetailedJobList(
                    this.state.page,
                    this.state.items,
                    searchText,
                    !!this.props.isEmployee,
                    this.props.createdExternal,
                    'all',
                    this.props.jobType
                        ? {
                              ...this.state.filters,
                              jobType: {
                                  value: this.props.jobType ? this.props.jobType : 2,
                                  key: 'jobType',
                                  operator: '=',
                              },
                          }
                        : this.state.filters,
                    this.state.sorts
                )
            })
        })

        this.updateSortsDebounced = debounce(500, false, (sorts) => {
            // console.log('Update sorts debounced')
            let {
                search: { data: searchText },
            } = this.state

            this.setState({ sorts, page: 0 }, () => {
                this.state.network.getDetailedJobList(
                    this.state.page,
                    this.state.items,
                    searchText,
                    !!this.props.isEmployee,
                    this.props.createdExternal,
                    'all',
                    this.props.jobType
                        ? {
                              ...this.state.filters,
                              jobType: {
                                  value: this.props.jobType ? this.props.jobType : 2,
                                  key: 'jobType',
                                  operator: '=',
                              },
                          }
                        : this.state.filters,
                    this.state.sorts
                )
            })
        })
    }

    store() {
        let {
            page,
            items,
            type,
            direction,
            select,
            searchable,
            selectedJob,
            jobOptions,
            selectSearch,
            selectOpen,
            firstTime,
            sub,
            opened,
            selected,
            scrollTop,
        } = this.state

        let toStore = {
            page,
            items,
            type,
            direction,
            select,
            searchable,
            selectedJob,
            jobOptions,
            selectSearch,
            selectOpen,
            firstTime,
            sub,
            opened,
            selected,
            scrollTop,
            createdExternal: !!this.props.createdExternal,
        }

        this.props.storeJobListState(toStore)
    }

    restore(state) {
        // console.log(
        //     'job list scrolling to Restoring state',
        //     state,
        //     this.props.createdExternal,
        //     state.createdExternal === !!this.props.createdExternal
        // )
        let restored = false
        if (state.createdExternal === !!this.props.createdExternal) {
            // console.log('Restoring the STATE')
            this.setState(state)
            restored = true
        }
        this.props.clearJobListState({})
        return restored
    }

    componentWillUnmount() {
        let { select } = this.props
        if (!select) {
            this.store()
        }
        this.state.updateSearch.cancel()
    }

    componentDidMount() {
        let {
            select,
            searchable,
            selectSearch,
            search: { data: searchText },
            scrollTop,
            page,
            items,
        } = this.state
        let { isEmployee, jobListState, workerId, createdExternal, network } = this.props

        network.getMappingData(isEmployee)

        if (jobListState && jobListState.stored) {
            if (this.restore(jobListState.state)) {
                ({ select, searchable, selectSearch, page, items, scrollTop } = jobListState.state)

                if (scrollTop > 0) {
                    let scrollTarget = scrollTop //Math.ceil(scrollTop / 311) * 311;
                    this.setState({
                        scrollTarget,
                        onLoaded: () => {
                            let { scrollTarget } = this.state
                            let container = document.getElementsByClassName('jobListContainer')[0]

                            setTimeout(() => {
                                // console.log('job list scrolling to', scrollTarget)
                                container.scrollTo({ top: scrollTarget, left: 0, behavior: 'smooth' })
                            }, 1000)
                        },
                    })
                }
            }
        }

        if (!searchable) {
            searchText = ''
        }

        if (select) {
            searchText = selectSearch
        }

        this.setToolbar()

        if (isEmployee) {
            this.state.network.getRecentJobList(page, items, searchText, workerId, !!isEmployee, createdExternal)
        } else {
            this.state.network.getDetailedJobList(
                page,
                items,
                searchText,
                !!isEmployee,
                createdExternal,
                'all',
                this.props.jobType
                    ? {
                          ...this.state.filters,
                          jobType: {
                              value: this.props.jobType ? this.props.jobType : 2,
                              key: 'jobType',
                              operator: '=',
                          },
                      }
                    : this.state.filters,
                this.state.sorts
            )
        }
    }

    updateFilters = (filters) => {
        this.updateFiltersDebounced(filters)
    }

    updateSorts = (sorts) => {
        this.updateSortsDebounced(sorts)
    }

    setToolbar() {
        let { login } = this.props
        let {
            page: currentPage,
            search: { data: searchText },
        } = this.state
        const { isEmployee, createdExternal } = this.props

        let pageItems = [
            {
                name: `${parseInt(currentPage) + 1}`,
                position: 'none',
                type: 'text',
            } /*,
				{
					name: `${pageCount}`,
					position: 'none',
					type: 'text'
				}*/,
        ]

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_job) {
            extraTools.push({
                name: 'Creazione guidata ordine cliente',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, jobwizard))
                },
            })
            extraTools.push({
                name: 'Crea nuovo ordine cliente',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, jobs, newEl, jobEdit))
                },
            })
            extraTools.push({
                name: 'Importa ordini cliente',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, fileimport))
                },
            })
        }

        const toolbar = [
            ...extraTools,
            {
                name: '',
                icon: 'arrow left',
                position: 'right',
                action: () => {
                    this.prev()
                },
            },
            ...pageItems,
            {
                name: '',
                icon: 'arrow right',
                position: 'none',
                action: () => {
                    this.next()
                },
            },
        ]

        const sorts = {
            jobCode: {
                name: 'jobCode',
                label: 'Codice commessa',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            client: {
                name: 'client',
                label: 'Cliente',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            description: {
                name: 'description',
                label: 'Descrizione',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            active: {
                name: 'active',
                label: 'Attiva',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            creationDate: {
                name: 'creationDate',
                label: 'Data di creazione',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            deliveryDate: {
                name: 'deliveryDate',
                label: 'Data di completamento',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            lastBillDate: {
                name: 'lastBillDate',
                label: 'Data ultima fatturazione',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            billed: {
                name: 'billed',
                label: 'Fatturato',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
            budgeted: {
                name: 'budgeted',
                label: 'Preventivato',
                onChange: (obj, value) => {
                    const { sorts } = this.state
                    sorts[obj.name] = value
                    this.updateSorts(sorts)
                },
                onReset: (obj) => {
                    const { sorts } = this.state

                    delete sorts[obj.name]
                    this.updateSorts(sorts)
                },
            },
        }

        const filters = {
            jobCode: {
                control: Input,
                name: 'jobCode',
                input: 'text',
                placeholder: 'Codice commessa',
                label: 'Codice commessa',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.jobCode = { value: e.target.value, key: 'jobCode', operator: '=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.jobCode
                    this.updateFilters(filters)
                },
            },
            client: {
                control: Input,
                name: 'client',
                input: 'text',
                placeholder: 'Cliente',
                label: 'Cliente',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.client = { value: e.target.value, key: 'client', operator: '=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.client
                    this.updateFilters(filters)
                },
            },
            description: {
                control: Input,
                name: 'description',
                input: 'text',
                placeholder: 'Descrizione',
                label: 'Descrizione',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.description = { value: e.target.value, key: 'description', operator: '=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.description
                    this.updateFilters(filters)
                },
            },
            active: {
                control: Checkbox,
                name: 'active',
                placeholder: 'Attiva',
                label: 'Attiva',
                onChange: (e, data) => {
                    const { filters } = this.state

                    filters.active = data.checked
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.active
                    this.updateFilters(filters)
                },
            },
            creationDateStart: {
                control: ReactDatePicker,
                name: 'creationDateStart',
                placeholder: 'Data di creazione (inizio)',
                label: 'Data di creazione (inizio)',
                useLocalValue: true,
                onChange: (e) => {
                    const { filters } = this.state

                    // console.log('Creation date start', typeof e, e, moment(e))
                    filters.creationDateStart = { value: moment(e).format('YYYY-MM-DD'), key: 'creationDateStart', operator: '>=' }
                    this.updateFilters(filters)
                    return filters.creationDateStart.value
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.creationDateStart
                    this.updateFilters(filters)
                },
            },
            deliveryDateStart: {
                control: ReactDatePicker,
                name: 'deliveryDateStart',
                placeholder: 'Data di completamento (inizio)',
                label: 'Data di completamento (inizio)',
                useLocalValue: true,
                onChange: (e) => {
                    const { filters } = this.state

                    filters.deliveryDateStart = { value: moment(e).format('YYYY-MM-DD'), key: 'deliveryDateStart', operator: '>=' }
                    this.updateFilters(filters)
                    return filters.deliveryDateStart.value
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.deliveryDateStart
                    this.updateFilters(filters)
                },
            },
            lastBillDateStart: {
                control: ReactDatePicker,
                name: 'lastBillDateStart',
                placeholder: 'Data ultima fatturazione (inizio)',
                label: 'Data ultima fatturazione (inizio)',
                useLocalValue: true,
                onChange: (e) => {
                    const { filters } = this.state

                    filters.lastBillDateStart = { value: moment(e).format('YYYY-MM-DD'), key: 'lastBillDateStart', operator: '>=' }
                    this.updateFilters(filters)
                    return filters.lastBillDateStart.value
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.lastBillDateStart
                    this.updateFilters(filters)
                },
            },
            creationDateEnd: {
                control: ReactDatePicker,
                name: 'creationDateEnd',
                placeholder: 'Data di creazione (fine)',
                label: 'Data di creazione (fine)',
                useLocalValue: true,
                onChange: (e) => {
                    const { filters } = this.state

                    filters.creationDateEnd = { value: moment(e).format('YYYY-MM-DD'), key: 'creationDateEnd', operator: '<=' }
                    this.updateFilters(filters)
                    return filters.creationDateEnd.value
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.creationDateEnd
                    this.updateFilters(filters)
                },
            },
            deliveryDateEnd: {
                control: ReactDatePicker,
                name: 'deliveryDateEnd',
                placeholder: 'Data di completamento (fine)',
                label: 'Data di completamento (fine)',
                useLocalValue: true,
                onChange: (e) => {
                    const { filters } = this.state

                    filters.deliveryDateEnd = { value: moment(e).format('YYYY-MM-DD'), key: 'deliveryDateEnd', operator: '<=' }
                    this.updateFilters(filters)
                    return filters.deliveryDateEnd.value
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.deliveryDateEnd
                    this.updateFilters(filters)
                },
            },
            lastBillDateEnd: {
                control: ReactDatePicker,
                name: 'lastBillDateEnd',
                placeholder: 'Data ultima fatturazione (fine)',
                label: 'Data ultima fatturazione (fine)',
                useLocalValue: true,
                onChange: (e) => {
                    const { filters } = this.state

                    filters.lastBillDateEnd = { value: moment(e).format('YYYY-MM-DD'), key: 'lastBillDateEnd', operator: '<=' }
                    this.updateFilters(filters)
                    return filters.lastBillDateEnd.value
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.lastBillDateEnd
                    this.updateFilters(filters)
                },
            },
            billedMin: {
                control: Input,
                name: 'billedMin',
                input: 'number',
                placeholder: 'Fatturato (maggiore di)',
                label: 'Fatturato (maggiore di)',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.billedMin = { value: e.target.value, key: 'billedMin', operator: '>=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.billedMin
                    this.updateFilters(filters)
                },
            },
            budgetedMin: {
                control: Input,
                name: 'budgetedMin',
                input: 'number',
                placeholder: 'Preventivato (maggiore di)',
                label: 'Preventivato (maggiore di)',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.budgetedMin = { value: e.target.value, key: 'budgetedMin', operator: '>=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.budgetedMin
                    this.updateFilters(filters)
                },
            },
            billedMax: {
                control: Input,
                name: 'billedMax',
                input: 'number',
                placeholder: 'Fatturato (minore di)',
                label: 'Fatturato (minore di)',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.billedMax = { value: e.target.value, key: 'billedMax', operator: '<=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.billedMax
                    this.updateFilters(filters)
                },
            },
            budgetedMax: {
                control: Input,
                name: 'budgetedMax',
                input: 'number',
                placeholder: 'Preventivato (minore di)',
                label: 'Preventivato (minore di)',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.budgetedMax = { value: e.target.value, key: 'budgetedMax', operator: '<=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.budgetedMax
                    this.updateFilters(filters)
                },
            },
        }

        initializePage(this, toolbar, filters, sorts)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { page, items, search, select, selectSearch, network, getDetailedJobList, deleteJob, firstTime, onLoaded } = this.state
        let { isEmployee, workerId, createdExternal, hideOther, organization } = this.props

        if (page !== prevState.page) {
            this.setToolbar()
        }

        if (this.props.createdExternal !== prevProps.createdExternal) {
            this.setState({ page: 0 })
        }

        if (
            (!this.props.deleteMapping.fetching && prevProps.deleteMapping.fetching) ||
            (!this.props.createMapping.fetching && prevProps.createMapping.fetching)
        ) {
            network.getMappingData(isEmployee)
        }

        if (
            !select &&
            (page !== prevState.page ||
                items !== prevState.items ||
                (deleteJob.fetching !== prevState.deleteJob.fetching && !deleteJob.fetching) ||
                (this.props.deletingCost !== prevProps.deletingCost &&
                    this.props.deleteCostResult !== prevProps.deleteCostResult &&
                    this.props.deleteCostStatus.success) ||
                (this.props.creatingJob !== prevProps.creatingJob &&
                    this.props.createResult !== prevProps.createResult &&
                    this.props.createStatus.success) ||
                organization.data !== prevProps.organization.data)
        ) {
            if (isEmployee) {
                this.state.network.getRecentJobList(page, items, search.data, workerId, isEmployee, createdExternal)
            } else {
                network.getDetailedJobList(
                    page,
                    items,
                    search.data,
                    isEmployee,
                    createdExternal,
                    'all',
                    this.props.jobType
                        ? {
                              ...this.state.filters,
                              jobType: {
                                  value: this.props.jobType ? this.props.jobType : 2,
                                  key: 'jobType',
                                  operator: '=',
                              },
                          }
                        : this.state.filters,
                    this.state.sorts
                )
            }
        } else if (!select && search !== prevState.search) {
            this.setState({
                page: 0,
            })

            if (isEmployee) {
                this.state.network.getRecentJobList(0, 10, search.data, workerId, isEmployee, !!createdExternal)
            } else {
                network.getDetailedJobList(
                    0,
                    10,
                    search.data,
                    isEmployee,
                    createdExternal,
                    'all',
                    this.props.jobType
                        ? {
                              ...this.state.filters,
                              jobType: {
                                  value: this.props.jobType ? this.props.jobType : 2,
                                  key: 'jobType',
                                  operator: '=',
                              },
                          }
                        : this.state.filters,
                    this.state.sorts
                )
            }
        } else if (select && getDetailedJobList !== prevState.getDetailedJobList) {
            if (getDetailedJobList.status.success) {
                let jobOptions = []
                let data = getDetailedJobList.data.data
                for (let i in data) {
                    let job = data[i][0]
                    jobOptions.push({
                        text: job.jobCode,
                        key: job.jobCode,
                        value: job,
                    })
                }

                this.setState({ jobOptions, selectedJob: jobOptions[0], selectOpen: false })
            }
        } else if (select && selectSearch !== prevState.selectSearch) {
            this.state.updateSearch(page, items, selectSearch, isEmployee)
        }

        if (!getDetailedJobList.fetching && prevState.getDetailedJobList.fetching) {
            if (onLoaded) {
                onLoaded()
                this.setState({ onLoaded: null })
            }
            if (firstTime) {
                this.setState({ firstTime: false })
            } else {
                setTimeout(() => {
                    this.setState({ selectOpen: true })
                }, 200)
            }
        }
    }

    goToPage(page) {
        this.setState({
            page,
        })
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    renderJob(key, worklist) {
        let { selected, opened } = this.state
        let { noActions, noBarcode, selectable, onChange } = this.props
        let extraProps = {
            noActions: noActions,
            noBarcode: noBarcode,
        }
        if (selectable) {
            extraProps.onClick = (job) => {
                if (onChange) {
                    onChange(job, this.props.workOrderMap)
                    this.setState({
                        selected: job,
                    })
                }
            }
        }

        if (worklist.length === 0) return null

        return (
            <Job
                {...extraProps}
                onOpened={(job, open, callback) => {
                    //opened[job.jobCode] = open;
                    let scrollTop = document.getElementById(`${job.jobCode}`).offsetTop - 100

                    this.setState(
                        {
                            //opened,
                            scrollTop,
                        },
                        callback
                    )
                }}
                showDetails={opened[worklist[0].jobCode]}
                type={this.state.type}
                key={key}
                job={worklist[0]}
                works={worklist}
                selected={onChange && selected && selected.jobCode === worklist[0].jobCode}
            />
        )
    }

    renderSelect() {
        let { jobOptions, selectedJob, selectOpen, selectSearch } = this.state
        let { onChange } = this.props

        return (
            <Form>
                <Form.Field
                    control={Select}
                    options={jobOptions}
                    label={<span className="label">Commessa</span>}
                    placeholder="Commessa"
                    value={selectedJob}
                    search
                    searchQuery={selectSearch}
                    open={selectOpen}
                    onSearchChange={(e, data) => {
                        this.setState({
                            selectSearch: data.searchQuery,
                        })
                    }}
                    clearable
                    onClick={() => {
                        this.setState({ selectOpen: true })
                    }}
                    onFocus={() => {
                        this.setState({ selectOpen: true })
                    }}
                    onChange={(e, data) => {
                        if (onChange) {
                            onChange(data.value)
                        }
                        this.setState({
                            selectSearch: data.value.jobCode,
                            selectedJob: data.value,
                            selectOpen: false,
                            firstTime: true,
                        })
                    }}
                    onBlur={() => {
                        this.setState({ selectOpen: false })
                    }}
                    searchInput={{ id: 'form-select-control-job' }}
                />
            </Form>
        )
    }

    render() {
        let { select: _s, direction: _d, type: _t, network, noActions, selectable, getUrgentJobList, organization, hideOther, ...rest } = this.props
        let { getDetailedJobList, direction, select } = this.state
        let content = <div />

        if (select) {
            content = this.renderSelect()
        } else if (getDetailedJobList.fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (!select && (getDetailedJobList.data.data, getDetailedJobList.data.count > 0)) {
            content = []
            if (getUrgentJobList && getUrgentJobList.data.data) {
                content.push(
                    ...Object.keys(getUrgentJobList.data.data).map((key) => {
                        let value = getUrgentJobList.data.data[key]
                        return this.renderJob(key, value)
                    })
                )
            }
            const datatomap = getDetailedJobList.data.order ? getDetailedJobList.data.order : Object.keys(getDetailedJobList.data.data)
            content.push(
                ...datatomap.map((key, index) => {
                    if (key === '00' && hideOther) {
                        return null
                    }
                    let value = getDetailedJobList.data.data[key]
                    return this.renderJob(key, value)
                })
            )
        } else {
            content = <Empty />
        }

        let dirobj = {
            [direction === 'row' ? 'overflowX' : 'overflowY']: 'scroll',
            [direction === 'row' ? 'overflowY' : 'overflowX']: 'hidden',
        }

        return (
            <View
                className="jobListContainer"
                fullw
                fullh
                {...{ column: direction === 'column', row: direction === 'row' }}
                style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    ...(select ? {} : dirobj),
                }}
                {...rest}
            >
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getDetailedJobList,
        getUrgentJobList,
        getRecentJobList,
        deleteJob,
        jobListState,
        createJob: { data: createResult, fetching: creatingJob, status: createStatus },
        deleteCost: { data: deleteCostResult, fetching: deletingCost, status: deleteCostStatus },
        search,
        login,
        organization,
        createMapping,
        getMappingData,
        deleteMapping,
    } = state

    const { isEmployee } = ownProps

    return {
        getDetailedJobList: isEmployee ? getRecentJobList : getDetailedJobList,
        getUrgentJobList,
        deleteJob,
        jobListState,
        search,
        deleteCostResult,
        deletingCost,
        deleteCostStatus,
        createResult,
        creatingJob,
        createStatus,
        pageCount: getDetailedJobList.data.paging ? getDetailedJobList.data.paging.pagecount : 0,
        currentPage: getDetailedJobList.data.paging ? getDetailedJobList.data.paging.page : 0,
        login,
        organization,
        createMapping,
        getMappingData,
        deleteMapping,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),

        storeJobListState: (result) => JOBLISTSTATE_ACTIONS.result(result, dispatch),
        clearJobListState: (result) => JOBLISTSTATE_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Jobs)))

/*
			let pageItems = [];
			currentPage = parseInt(currentPage);
			if (pageCount > 10) {
				let middle = Math.floor(pageCount / 2);
				// console.log('Current page is', currentPage);
				middle = currentPage > 2 && currentPage < pageCount - 2 ? currentPage + 1 : middle;
				let pages = [
					1,
					2,
					3,
					'...',
					middle - 1,
					middle,
					middle + 1,
					'...',
					pageCount - 2,
					pageCount - 1,
					pageCount
				];

				if (middle + 1 === pageCount - 2) {
					pages.splice(6, 1);
				}

				if (middle - 1 === 3) {
					pages.splice(2, 1);
				}

				for (let page of pages) {
					let number = parseInt(page);
					let icon = '';
					if (isNaN(page)) {
						number = -1;
					}

					let item = {
						name: page,
						position: 'none',
						selected: () => {
							return currentPage === page - 1;
						},
						action: () => {
							if (number < 0) return;

							this.goToPage(number - 1);
						}
					};

					if (isNaN(page)) {
						item.icon = 'ellipsis horizontal';
					}
					pageItems.push(item);
				}
			} else if (pageCount > 0) {
				for (let i = 0; i < pageCount; i++) {
					pageItems.push({
						name: `${i + 1}`,
						position: 'none',
						selected: () => {
							return currentPage === i;
						},
						action: () => {
							this.goToPage(i);
						}
					});
				}
			}*/
